import createModule from './stream.js';

// Initialize Module asynchronously
let ModulePromise = createModule();

// Test Module functions
export function testModuleFunctions() {
    ModulePromise.then((Module) => {
        const functionsToTest = [
            'FS_createDataFile',
            'set_audio',
            'init',
            'get_transcribed',
            'set_status', // Using `set_status` as provided by WASM
        ];

        functionsToTest.forEach((funcName) => {
            if (typeof Module[funcName] === 'function') {
                console.log(`${funcName} is loaded successfully.`);
            } else {
                console.error(`${funcName} is NOT loaded.`);
            }
        });
    }).catch((error) => {
        console.error("Failed to load Module:", error);
    });
}

// web audio context
var context = null;
// audio data
var audio = null;
var audio0 = null;
var instance = null;
// model name
var model_whisper = null;
var model = 'whisper.bin';

var Module = {
    // print: printTextarea,
    // printErr: printTextarea,
    setStatus: function(text) {
        console.log('js: ' + text);
    },
    monitorRunDependencies: function(left) {
    },
    preRun: function() {
        console.log('js: Preparing ...');
    },
    postRun: function() {
        console.log('js: Initialized successfully!');
    }
};

const kSampleRate = 16000;
const kRestartRecording_s = 120;
const kIntervalAudio_ms = 5000; // pass the recorded audio to the C++ instance at this rate

var mediaRecorder = null;
var doRecording = false;
var startTime = 0;

var nLines = 0;
var intervalUpdate = null;
var transcribedAll = '';

// Function to store files in WASM FS
function storeFS(fname, buf) {
    ModulePromise.then((Module) => {
        Module.FS_createDataFile("/", fname, buf, true, true);
    });
}

// Function to load Whisper model
export function loadWhisper() {
    const model = 'whisper.bin';
    const url = './model/' + model;
    const dst = 'whisper.bin';
    console.log('whisper.bin being loaded');
    fetch(url)
        .then(response => {
            if (!response.ok) throw new Error('Network response was not ok');
            return response.arrayBuffer();
        })
        .then(data => {
            const buf = new Uint8Array(data);
            storeFS(dst, buf);
            console.log('whisper.bin successfully loaded');
        })
        .catch(error => {
            console.error('Error loading model:', error);
        });
}

function stopRecording() {
    ModulePromise.then((Module) => {
        Module.set_status("paused"); // Using `set_status` as provided by WASM
        doRecording = false;
        audio0 = null;
        audio = null;
        context = null;
    });
}

// Updated startRecording function to accept an external audio stream
function startRecording(audioStream) {
    if (!context) {
        context = new AudioContext({
            sampleRate: kSampleRate,
            channelCount: 1,
            echoCancellation: false,
            autoGainControl: true,
            noiseSuppression: true,
        });
    }

    ModulePromise.then((Module) => {
        Module.set_status(""); // Using `set_status` as provided by WASM

        doRecording = true;
        startTime = Date.now();

        var chunks = [];
        var stream = audioStream;  // Use the provided audio stream

        if (stream) {
            mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.ondataavailable = function (e) {
                chunks.push(e.data);

                var blob = new Blob(chunks, { 'type': 'audio/ogg; codecs=opus' });
                var reader = new FileReader();

                reader.onload = function (event) {
                    var buf = new Uint8Array(reader.result);

                    if (!context) {
                        return;
                    }
                    context.decodeAudioData(buf.buffer, function (audioBuffer) {
                        var offlineContext = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);
                        var source = offlineContext.createBufferSource();
                        source.buffer = audioBuffer;
                        source.connect(offlineContext.destination);
                        source.start(0);

                        offlineContext.startRendering().then(function (renderedBuffer) {
                            audio = renderedBuffer.getChannelData(0);
                            var audioAll = new Float32Array(audio0 == null ? audio.length : audio0.length + audio.length);
                            if (audio0 != null) {
                                audioAll.set(audio0, 0);
                            }
                            audioAll.set(audio, audio0 == null ? 0 : audio0.length);

                            if (instance) {
                                Module.set_audio(instance, audioAll);
                            }
                        });
                    }, function (e) {
                        audio = null;
                    });
                }

                reader.readAsArrayBuffer(blob);
            };

            mediaRecorder.onstop = function (e) {
                if (doRecording) {
                    setTimeout(function () {
                        startRecording(stream);  // Reuse the same audio stream
                    });
                }
            };

            mediaRecorder.start(kIntervalAudio_ms);
        } else {
            console.log('Error: No audio stream provided.');
        }

        var interval = setInterval(function () {
            if (!doRecording) {
                clearInterval(interval);
                mediaRecorder.stop();
                stream.getTracks().forEach(function (track) {
                    track.stop();
                });

                mediaRecorder = null;
            }

            // if audio length is more than kRestartRecording_s seconds, restart recording
            if (audio != null && audio.length > kSampleRate * kRestartRecording_s) {
                if (doRecording) {
                    clearInterval(interval);
                    audio0 = audio;
                    audio = null;
                    mediaRecorder.stop();
                    stream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                }
            }
        }, 100);
    });
}

// Capture audio stream from the video element and pass it to startRecording
export function onStart(audioStream) {
    ModulePromise.then((Module) => {
        if (!instance) {
            instance = Module.init('whisper.bin');

            if (instance) {
                console.log("js: whisper initialized, instance: " + instance);
            }
        }

        if (!instance) {
            console.log("js: failed to initialize whisper");
            return;
        }


        if (audioStream) {
            startRecording(audioStream);  // Pass the audio stream to startRecording
        } else {
            console.error('No audio track found in the video stream.');
        }

        // Interval for updating transcriptions
        intervalUpdate = setInterval(function () {
            const transcribed = Module.get_transcribed();

            if (transcribed != null && transcribed.length > 1) {
                transcribedAll += transcribed + '<br>';
                nLines++;

                // make event start
                // Dispatch custom event with transcription data
                const transcriptionEvent = new CustomEvent('speech_transcription', {
                    detail: { text: transcribed },
                });
                window.dispatchEvent(transcriptionEvent);  // Dispatches event globally
                // make event stop

                // if more than 10 lines, remove the first line
                if (nLines > 10) {
                    const i = transcribedAll.indexOf('<br>');
                    if (i > 0) {
                        transcribedAll = transcribedAll.substring(i + 4);
                        nLines--;
                    }
                }
            }
        }, 100);
    });
}

export function onStop() {
    stopRecording();
}

// Automatically call testModuleFunctions when the module loads
testModuleFunctions();
loadWhisper();
