import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { IconDotsHorizontal } from '../../../base/icons/svg';
import { getParticipantById } from '../../../base/participants/functions';
import Popover from '../../../base/popover/components/Popover.web';
import Button from '../../../base/ui/components/web/Button';
import { BUTTON_TYPES } from '../../../base/ui/constants.any';
import { copyText } from '../../../base/util/copyText.web';
import { handleLobbyChatInitialized, openChat } from '../../actions.web';
const useStyles = makeStyles()(theme => {
    return {
        messageMenuButton: {
            padding: '2px'
        },
        menuItem: {
            padding: '8px 16px',
            cursor: 'pointer',
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.action03
            }
        },
        menuPanel: {
            backgroundColor: theme.palette.ui03,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[3],
            overflow: 'hidden'
        },
        copiedMessage: {
            position: 'fixed',
            backgroundColor: theme.palette.ui03,
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            zIndex: 1000,
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
            pointerEvents: 'none'
        },
        showCopiedMessage: {
            opacity: 1
        }
    };
});
const MessageMenu = ({ message, participantId, isLobbyMessage, shouldDisplayChatMessageMenu }) => {
    const dispatch = useDispatch();
    const { classes, cx } = useStyles();
    const { t } = useTranslation();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ top: 0,
        left: 0 });
    const buttonRef = useRef(null);
    const participant = useSelector((state) => getParticipantById(state, participantId));
    const handleMenuClick = useCallback(() => {
        setIsPopoverOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setIsPopoverOpen(false);
    }, []);
    const handlePrivateClick = useCallback(() => {
        if (isLobbyMessage) {
            dispatch(handleLobbyChatInitialized(participantId));
        }
        else {
            dispatch(openChat(participant));
        }
        handleClose();
    }, [dispatch, isLobbyMessage, participant, participantId]);
    const handleCopyClick = useCallback(() => {
        copyText(message)
            .then(success => {
            if (success) {
                if (buttonRef.current) {
                    const rect = buttonRef.current.getBoundingClientRect();
                    setPopupPosition({
                        top: rect.top - 30,
                        left: rect.left
                    });
                }
                setShowCopiedMessage(true);
                setTimeout(() => {
                    setShowCopiedMessage(false);
                }, 2000);
            }
            else {
                console.error('Failed to copy text');
            }
        })
            .catch(error => {
            console.error('Error copying text:', error);
        });
        handleClose();
    }, [message]);
    const popoverContent = (React.createElement("div", { className: classes.menuPanel },
        shouldDisplayChatMessageMenu && (React.createElement("div", { className: classes.menuItem, onClick: handlePrivateClick }, t('Private Message'))),
        React.createElement("div", { className: classes.menuItem, onClick: handleCopyClick }, t('Copy'))));
    return (React.createElement("div", null,
        React.createElement("div", { ref: buttonRef },
            React.createElement(Popover, { content: popoverContent, onPopoverClose: handleClose, position: 'top', trigger: 'click', visible: isPopoverOpen },
                React.createElement(Button, { accessibilityLabel: t('toolbar.accessibilityLabel.moreOptions'), className: classes.messageMenuButton, icon: IconDotsHorizontal, onClick: handleMenuClick, type: BUTTON_TYPES.TERTIARY }))),
        showCopiedMessage && ReactDOM.createPortal(React.createElement("div", { className: cx(classes.copiedMessage, { [classes.showCopiedMessage]: showCopiedMessage }), style: { top: `${popupPosition.top}px`,
                left: `${popupPosition.left}px` } }, t('Message Copied')), document.body)));
};
export default MessageMenu;
