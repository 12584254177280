class SnapShot {
    constructor(videoElement) {
        this.videoElement = videoElement;

        // Add event listener for middle mouse button click
        videoElement.addEventListener('mousedown', (event) => {
            if (event.button === 1) { // 1 corresponds to middle mouse button
                event.preventDefault(); // Prevent default middle mouse behavior
                this.captureVideoSnapshot(); // Capture video snapshot on middle mouse click
            }
        });
    }

    // Method to capture snapshot from the video element
    captureVideoSnapshot() {
        const videoElement = this.videoElement;

        // Create an offscreen canvas for the snapshot
        const snapshotCanvas = document.createElement('canvas');
        const context = snapshotCanvas.getContext('2d');

        // Set canvas dimensions to match the video
        snapshotCanvas.width = videoElement.videoWidth;
        snapshotCanvas.height = videoElement.videoHeight;

        // Draw the current video frame to the canvas
        context.drawImage(videoElement, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

        // Convert the canvas content to a data URL
        const imageDataUrl = snapshotCanvas.toDataURL('image/png');
        console.log('hansoo Snapshot captured:', imageDataUrl);

        // Optionally, trigger a download of the snapshot
        this.downloadImage(imageDataUrl, 'video_snapshot.png');
    }

    // Method to capture screen snapshot
    captureScreenSnapshot() {
        // Request permission to capture the screen
        navigator.mediaDevices.getDisplayMedia({ video: true })
            .then((stream) => {
                const screenVideoElement = document.createElement('video');
                screenVideoElement.srcObject = stream;
                screenVideoElement.play();

                // When the screen stream is playing, draw it to the canvas
                screenVideoElement.onloadedmetadata = () => {
                    const snapshotCanvas = document.createElement('canvas');
                    snapshotCanvas.width = screenVideoElement.videoWidth;
                    snapshotCanvas.height = screenVideoElement.videoHeight;

                    const context = snapshotCanvas.getContext('2d');
                    context.drawImage(screenVideoElement, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

                    // Convert the canvas content to a data URL
                    const imageDataUrl = snapshotCanvas.toDataURL('image/png');
                    console.log('hansoo Screen snapshot captured:', imageDataUrl);

                    // Optionally, trigger a download of the snapshot
                    this.downloadImage(imageDataUrl, 'screen_snapshot.png');

                    // Stop the screen stream after capturing
                    stream.getTracks().forEach(track => track.stop());
                };
            })
            .catch((err) => {
                console.error('hansoo Error capturing screen:', err);
            });
    }

    // Method to trigger a download of the snapshot
    downloadImage(dataUrl, filename) {
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

export { SnapShot };
