import React, { Component } from 'react';
import { connect as reduxConnect } from 'react-redux';
import { connect } from '../../../base/connection/actions.web';
import { toJid } from '../../../base/connection/functions';
import { translate, translateToHTML } from '../../../base/i18n/functions';
import { JitsiConnectionErrors } from '../../../base/lib-jitsi-meet';
import Dialog from '../../../base/ui/components/web/Dialog';
import Input from '../../../base/ui/components/web/Input';
import { authenticateAndUpgradeRole, cancelLogin } from '../../actions.web';
/**
 * Component that renders the login in conference dialog.
 *
 *  @returns {React$Element<any>}
 */
class LoginDialog extends Component {
    /**
     * Initializes a new {@code LoginDialog} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
        this._onCancelLogin = this._onCancelLogin.bind(this);
        this._onLogin = this._onLogin.bind(this);
        this._onUsernameChange = this._onUsernameChange.bind(this);
        this._onPasswordChange = this._onPasswordChange.bind(this);
    }
    /**
     * Called when the cancel button is clicked.
     *
     * @private
     * @returns {void}
     */
    _onCancelLogin() {
        const { dispatch } = this.props;
        dispatch(cancelLogin());
    }
    /**
     * Notifies this LoginDialog that the login button (OK) has been pressed by
     * the user.
     *
     * @private
     * @returns {void}
     */
    _onLogin() {
        const { _conference: conference, _configHosts: configHosts, dispatch } = this.props;
        const { password, username } = this.state;
        const jid = toJid(username, configHosts ?? {
            authdomain: '',
            domain: ''
        });
        if (conference) {
            dispatch(authenticateAndUpgradeRole(jid, password, conference));
        }
        else {
            dispatch(connect(jid, password));
        }
    }
    /**
     * Callback for the onChange event of the field.
     *
     * @param {string} value - The static event.
     * @returns {void}
     */
    _onPasswordChange(value) {
        this.setState({
            password: value
        });
    }
    /**
     * Callback for the onChange event of the username input.
     *
     * @param {string} value - The new value.
     * @returns {void}
     */
    _onUsernameChange(value) {
        this.setState({
            username: value
        });
    }
    /**
     * Renders an optional message, if applicable.
     *
     * @returns {ReactElement}
     * @private
     */
    renderMessage() {
        const { _configHosts: configHosts, _connecting: connecting, _error: error, _progress: progress, t } = this.props;
        const { username, password } = this.state;
        const messageOptions = {};
        let messageKey;
        if (progress && progress < 1) {
            messageKey = 'connection.FETCH_SESSION_ID';
        }
        else if (error) {
            const { name } = error;
            if (name === JitsiConnectionErrors.PASSWORD_REQUIRED) {
                const { credentials } = error;
                if (credentials
                    && credentials.jid === toJid(username, configHosts ?? { authdomain: '',
                        domain: '' })
                    && credentials.password === password) {
                    messageKey = 'dialog.incorrectPassword';
                }
            }
            else if (name) {
                messageKey = 'dialog.connectErrorWithMsg';
                messageOptions.msg = `${name} ${error.message}`;
            }
        }
        else if (connecting) {
            messageKey = 'connection.CONNECTING';
        }
        if (messageKey) {
            return (React.createElement("span", null, translateToHTML(t, messageKey, messageOptions)));
        }
        return null;
    }
    /**
     * Implements {@Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { _connecting: connecting, t } = this.props;
        const { password, username } = this.state;
        return (React.createElement(Dialog, { disableAutoHideOnSubmit: true, disableBackdropClose: true, hideCloseButton: true, ok: {
                disabled: connecting
                    || !password
                    || !username,
                translationKey: 'dialog.login'
            }, onCancel: this._onCancelLogin, onSubmit: this._onLogin, titleKey: t('dialog.authenticationRequired') },
            React.createElement(Input, { autoFocus: true, id: 'login-dialog-username', label: t('dialog.user'), name: 'username', onChange: this._onUsernameChange, placeholder: t('dialog.userIdentifier'), type: 'text', value: username }),
            React.createElement("br", null),
            React.createElement(Input, { className: 'dialog-bottom-margin', id: 'login-dialog-password', label: t('dialog.userPassword'), name: 'password', onChange: this._onPasswordChange, placeholder: t('dialog.password'), type: 'password', value: password }),
            this.renderMessage()));
    }
}
/**
 * Maps (parts of) the Redux state to the associated props for the
 * {@code LoginDialog} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {IProps}
 */
function mapStateToProps(state) {
    const { error: authenticateAndUpgradeRoleError, progress, thenableWithCancel } = state['features/authentication'];
    const { authRequired, conference } = state['features/base/conference'];
    const { hosts: configHosts } = state['features/base/config'];
    const { connecting, error: connectionError } = state['features/base/connection'];
    return {
        _conference: authRequired || conference,
        _configHosts: configHosts,
        _connecting: Boolean(connecting) || Boolean(thenableWithCancel),
        _error: connectionError || authenticateAndUpgradeRoleError,
        _progress: progress
    };
}
export default translate(reduxConnect(mapStateToProps)(LoginDialog));
