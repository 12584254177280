import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../base/ui/components/web/Dialog';
/**
 * Dialog to confirm playing a video shared from a remote participant.
 *
 * @returns {JSX.Element}
 */
export default function ShareVideoConfirmDialog({ actorName, onSubmit }) {
    const { t } = useTranslation();
    return (React.createElement(Dialog, { onSubmit: onSubmit, title: t('dialog.shareVideoConfirmPlayTitle', {
            name: actorName
        }) },
        React.createElement("div", null, t('dialog.shareVideoConfirmPlay'))));
}
