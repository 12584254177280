//let GestureRecognizer, FilesetResolver;
import { GestureRecognizer, FilesetResolver } from "./mylibs/mediapipe/task-vision/";


class HandTrack {
    constructor(videoElement) {
        this.videoElement = videoElement;
        this.videoElement.autoplay = true; // Video element is expected to be handled externally
        this.gestureRecognizer = undefined;
        this.lastVideoTime = -1;
        this.hand_activated = false;
        this.skip_count = 0;
        this.skip_limit = 4;
        // Initialize GestureRecognizer asynchronously
        this.initializeGestureRecognizer();
    }

    /**
     * Asynchronously loads the @mediapipe/tasks-vision module and initializes the GestureRecognizer
     */
async initializeGestureRecognizer() {
    try {

        console.log("hansoo 1 Creating FilesetResolver...");
        //const vision = await FilesetResolver.forVisionTasks("./mylibs/mediapipe/task-vision/wasm");  // Use local path for WASM file
        const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm");

        console.log("hansoo 2 Creating GestureRecognizer...");
        this.gestureRecognizer = await GestureRecognizer.createFromOptions(vision, {
            baseOptions: {
                modelAssetPath: "https://storage.googleapis.com/mediapipe-models/gesture_recognizer/gesture_recognizer/float16/1/gesture_recognizer.task",
                //modelAssetPath: "./mylibs/mediapipe/task-vision/gesture_recognizer.task",  // Local model path
                delegate: "GPU"
            },
            runningMode: "VIDEO",  // Assuming it's a video stream
        });

        console.log("hansoo 3 GestureRecognizer created successfully. Ready for predictions.");
        this.startPrediction();
    } catch (error) {
        console.error("hansoo 4 Error initializing GestureRecognizer:", error);
    }
}

/*
    async initializeGestureRecognizer() {
        try {
            console.log("xx hansoo Loading MediaPipe Vision module for gesture recognition...");

            // Dynamically import the @mediapipe/tasks-vision package
            await import("@mediapipe/tasks-vision")
                .then(module => {
                    GestureRecognizer = module.GestureRecognizer;
                    FilesetResolver = module.FilesetResolver;
                })
                .catch(error => console.error("hansoo Error loading @mediapipe/tasks-vision:", error));

            console.log("hansoo Creating FilesetResolver...");
            const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm");

            console.log("hansoo Creating GestureRecognizer...");
            this.gestureRecognizer = await GestureRecognizer.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath: "https://storage.googleapis.com/mediapipe-models/gesture_recognizer/gesture_recognizer/float16/1/gesture_recognizer.task",
                    delegate: "GPU"
                },
                runningMode: "VIDEO", // Assuming it's a video stream
            });

            console.log("hansoo GestureRecognizer created successfully. Ready for predictions.");
            this.startPrediction();
        } catch (error) {
            console.error("hansoo Error initializing GestureRecognizer:", error);
        }
    }
*/
    /**
     * Start prediction on the video element
     */
    startPrediction() {
        if (!this.gestureRecognizer) {
            alert("hansoo Gesture recognizer is not loaded yet");
            return;
        }

        this.predict(); // Begin gesture recognition on the provided video element
    }

    /**
     * Detect gestures and log index finger position if hand is activated
     */
    async predict() {
        const startTimeMs = performance.now();

        // Only predict if the video time has advanced
        if (this.lastVideoTime !== this.videoElement.currentTime) {
            this.lastVideoTime = this.videoElement.currentTime;

            // Perform gesture recognition on the current video frame
            try {
                const results = await this.gestureRecognizer.recognizeForVideo(this.videoElement, Date.now());
                if (results && results.gestures.length > 0) {
                    const isOpen_Palm = results.gestures[0][0].categoryName === "Open_Palm";
                    const isClosed_Fist = results.gestures[0][0].categoryName === "Closed_Fist";
                    const indexFingerTip = results.landmarks[0]?.[8]; // Index finger tip landmark

                    if (this.hand_activated && isClosed_Fist) {
                        console.log("hansoo Hand off");
                        this.hand_activated = false;
                        if (indexFingerTip) {
                            const event = new CustomEvent('indexFingerTipDetected', {
                                detail: {
                                    activate: this.hand_activated,
                                    x: indexFingerTip.x,
                                    y: indexFingerTip.y
                                }
                            });
                            window.dispatchEvent(event);
                            this.skip_count = 0;
                        }
                    }

                    if (!this.hand_activated && isOpen_Palm) {
                        console.log("hansoo Hand on");
                        this.hand_activated = true;
                    }

                    if (this.hand_activated && indexFingerTip) {

                        if(this.skip_count == 0)
                        {
                            const event = new CustomEvent('indexFingerTipDetected', {
                                detail: {
                                    activate: this.hand_activated,
                                    x: indexFingerTip.x,
                                    y: indexFingerTip.y
                                }
                            });
                            window.dispatchEvent(event);
                        }
                        if(++this.skip_count >= this.skip_limit)
                        {
                            this.skip_count = 0;
                        }
                    }
                }
            } catch (error) {
                console.error("hansoo Error during gesture recognition:", error);
            }
        }

        // Continue predicting
        window.requestAnimationFrame(() => this.predict());
    }

    /**
     * Stop processing and cleanup resources
     */
    destroy() {
        this.gestureRecognizer = undefined;
    }
}

export { HandTrack };
