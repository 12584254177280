import { assign } from 'lodash-es';
import ReducerRegistry from '../base/redux/ReducerRegistry';
import { ADD_TO_OFFSET, ADD_TO_OFFSET_LEFT, ADD_TO_OFFSET_RIGHT, INIT_REORDER_STATS, INIT_SEARCH, RESET_SEARCH_CRITERIA, SET_PANNING, SET_TIMELINE_BOUNDARY, TOGGLE_FACE_EXPRESSIONS, UPDATE_SORTED_SPEAKER_STATS_IDS, UPDATE_STATS } from './actionTypes';
/**
 * The initial state of the feature speaker-stats.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    stats: {},
    isOpen: false,
    pendingReorder: true,
    criteria: null,
    showFaceExpressions: false,
    sortedSpeakerStatsIds: [],
    timelineBoundary: null,
    offsetLeft: 0,
    offsetRight: 0,
    timelinePanning: {
        active: false,
        x: 0
    }
};
ReducerRegistry.register('features/speaker-stats', (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_SEARCH:
            return _updateCriteria(state, action);
        case UPDATE_STATS:
            return _updateStats(state, action);
        case INIT_REORDER_STATS:
            return _initReorderStats(state);
        case UPDATE_SORTED_SPEAKER_STATS_IDS:
            return _updateSortedSpeakerStats(state, action);
        case RESET_SEARCH_CRITERIA:
            return _updateCriteria(state, { criteria: null });
        case TOGGLE_FACE_EXPRESSIONS: {
            return {
                ...state,
                showFaceExpressions: !state.showFaceExpressions
            };
        }
        case ADD_TO_OFFSET: {
            return {
                ...state,
                offsetLeft: state.offsetLeft + action.value,
                offsetRight: state.offsetRight + action.value
            };
        }
        case ADD_TO_OFFSET_RIGHT: {
            return {
                ...state,
                offsetRight: state.offsetRight + action.value
            };
        }
        case ADD_TO_OFFSET_LEFT: {
            return {
                ...state,
                offsetLeft: state.offsetLeft + action.value
            };
        }
        case SET_TIMELINE_BOUNDARY: {
            return {
                ...state,
                timelineBoundary: action.boundary
            };
        }
        case SET_PANNING: {
            return {
                ...state,
                timelinePanning: action.panning
            };
        }
    }
    return state;
});
/**
 * Reduces a specific Redux action INIT_SEARCH of the feature
 * speaker-stats.
 *
 * @param {Object} state - The Redux state of the feature speaker-stats.
 * @param {Action} action - The Redux action INIT_SEARCH to reduce.
 * @private
 * @returns {Object} The new state after the reduction of the specified action.
 */
function _updateCriteria(state, { criteria }) {
    return assign({}, state, { criteria });
}
/**
 * Reduces a specific Redux action UPDATE_STATS of the feature speaker-stats.
 *
 * @param {Object} state - The Redux state of the feature speaker-stats.
 * @param {Action} action - The Redux action UPDATE_STATS to reduce.
 * @private
 * @returns {Object} - The new state after the reduction of the specified action.
 */
function _updateStats(state, { stats }) {
    return {
        ...state,
        stats
    };
}
/**
 * Reduces a specific Redux action UPDATE_SORTED_SPEAKER_STATS_IDS of the feature speaker-stats.
 *
 * @param {Object} state - The Redux state of the feature speaker-stats.
 * @param {Action} action - The Redux action UPDATE_SORTED_SPEAKER_STATS_IDS to reduce.
 * @private
 * @returns {Object} The new state after the reduction of the specified action.
 */
function _updateSortedSpeakerStats(state, { participantIds }) {
    return {
        ...state,
        sortedSpeakerStatsIds: participantIds,
        pendingReorder: false
    };
}
/**
 * Reduces a specific Redux action INIT_REORDER_STATS of the feature
 * speaker-stats.
 *
 * @param {Object} state - The Redux state of the feature speaker-stats.
 * @private
 * @returns {Object} The new state after the reduction of the specified action.
 */
function _initReorderStats(state) {
    return assign({}, state, { pendingReorder: true });
}
