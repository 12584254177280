import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => {
    return {
        emojiGrid: {
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '4px',
            backgroundColor: theme.palette.ui03
        },
        emojiButton: {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '1.5em'
        }
    };
});
const EmojiSelector = ({ onSelect }) => {
    const { classes } = useStyles();
    const emojiMap = {
        thumbsUp: '👍',
        redHeart: '❤️',
        faceWithTearsOfJoy: '😂',
        faceWithOpenMouth: '😮',
        fire: '🔥'
    };
    const emojiNames = Object.keys(emojiMap);
    const handleSelect = useCallback((emoji) => (event) => {
        event.preventDefault();
        onSelect(emoji);
    }, [onSelect]);
    return (React.createElement("div", { className: classes.emojiGrid }, emojiNames.map(name => (React.createElement("span", { className: classes.emojiButton, key: name, onClick: handleSelect(emojiMap[name]) }, emojiMap[name])))));
};
export default EmojiSelector;
